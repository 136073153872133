import { Button } from "@fluentui/react-components";

interface iWelcomeMessage {
  setShowLoginForm: React.Dispatch<React.SetStateAction<boolean>>;
}
const WelcomeMessage: React.FC<iWelcomeMessage> = ({ setShowLoginForm }) => (
  <>
    <p>
      Zuzo is the simplest way for businesses to provide a universally valuable
      perk, to increase employee appreciation and performance.
    </p>
    <Button
      type="submit"
      className="btn-mint btn-auto"
      onClick={() => {
        setShowLoginForm(true);
      }}
    >
      Connect Your Account
    </Button>
  </>
);
export default WelcomeMessage;
