import { AxiosResponse } from "axios";
import axiosBotRequest from "../helpers/axios-bot";
import {
  CallbackResponse,
  CompanyValuesResponse,
  CreateNomination,
  CreateVote,
  PeerToPeerIssuance,
  SubscriptionStatus,
  TeamMemberResponse,
  TeamsBotCredentials,
  TeamsBotCredentialUpdateResponse,
} from "../types/api-types";

export const getTeamMembers = async (
  tenantId: string
): Promise<AxiosResponse<TeamMemberResponse>> =>
  axiosBotRequest().get(`/api/members?tenantId=${tenantId}`);

export const getCompanyValues = async (
  tenantId: string
): Promise<AxiosResponse<CompanyValuesResponse>> =>
  axiosBotRequest().get(`/api/company-values?tenantId=${tenantId}`);

export const getSubscriptionStatus = async (
  userId: string,
  tenantId: string
): Promise<AxiosResponse<SubscriptionStatus>> =>
  axiosBotRequest().get(
    `/api/subscription-check?userId=${userId}&tenantId=${tenantId}`
  );
export const getActivityId = async (
  id: string
): Promise<AxiosResponse<PeerToPeerIssuance>> =>
  axiosBotRequest().get(`/api/peer-to-peer-issuance?id=${id}`);

export const submitRecognition = async (
  payload: CreateNomination | CreateVote
) => axiosBotRequest().post("/api/recognise", payload);

export const getTeamsBotCredential = async (
  tenantId: string
): Promise<AxiosResponse<TeamsBotCredentialUpdateResponse>> =>
  axiosBotRequest().get(`/api/teams-bot-credential?tenantId=${tenantId}`);

export const sendOtp = async (payload: {
  email: string;
  otpModeEnabled: boolean;
}): Promise<AxiosResponse<void>> =>
  axiosBotRequest().post(`/api/send-otp`, payload);

export const verifyOtp = async (
  code: string
): Promise<AxiosResponse<CallbackResponse>> =>
  axiosBotRequest().get(`/api/verify-otp?code=${code}`);

export const getCompanyDetails = async (payload: {
  token: string;
}): Promise<AxiosResponse<string>> =>
  axiosBotRequest().post(`/api/get-company-details`, payload);

export const updateTeamsBotCredential = async (
  id: string,
  payload: TeamsBotCredentials
): Promise<AxiosResponse<TeamsBotCredentialUpdateResponse>> =>
  axiosBotRequest().post(`/api/teams-bot-credential?id=${id}`, payload);

export const sendWelcomeMessage = async (payload: {
  tenantId: string;
  messageType: number;
  conversationId: string;
  teamworkTagId: null;
}): Promise<AxiosResponse<void>> =>
  axiosBotRequest().post(`/api/send-message-to-channel`, payload);

export const sendUserWelcomeMessage = async (payload: {
  tenantId: string;
  messageType: number;
}): Promise<AxiosResponse<void>> =>
  axiosBotRequest().post(`/api/send-message-to-user`, payload);
