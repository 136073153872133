import {
  Button,
  Field,
  Input,
  InputProps,
  MessageBar,
  MessageBarBody,
  Spinner,
} from "@fluentui/react-components";
import { useId } from "@fluentui/react-components";
import { UserInfo } from "@microsoft/teamsfx";
import { useState } from "react";
import * as bot from "../../../api/bot.service";
import {
  TeamsBotCredentials,
  TeamsBotCredentialUpdateResponse,
} from "../../../types/api-types";

interface iOtpForm {
  setIsAccountLinked: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLoginForm: React.Dispatch<React.SetStateAction<boolean>>;
  setHasCode: React.Dispatch<React.SetStateAction<boolean>>;
  teamsBotCredentials: TeamsBotCredentialUpdateResponse;
  userInfo: UserInfo;
}

const OtpForm: React.FC<iOtpForm> = ({
  setIsAccountLinked,
  setHasCode,
  setShowLoginForm,
  teamsBotCredentials,
  userInfo,
}) => {
  const fieldIdOtp = useId("otp");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [otp, setOtp] = useState<string | undefined>();

  const onOtpChange: InputProps["onChange"] = (ev, data) => {
    ev.preventDefault();
    // Uncontrolled inputs can be notified of changes to the value
    console.log(`New value: "${data.value}"`);
    setOtp(data.value);
  };

  const handleOtpSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    if (otp!.length < 1) {
      setErrorMessage("Please enter an OTP code first.");
      setIsLoading(false);
      return;
    }

    try {
      let dataTokens = await bot.verifyOtp(otp!);
      // resetting fields
      setOtp(undefined);
      console.log("dataTokens.data", dataTokens.data);
      if (dataTokens.data === null) {
        // update state
        setErrorMessage("OTP is not valid, please try again.");
        setIsLoading(false);
        return;
      }
      const tempToken = dataTokens.data.data.token;
      const payload = {
        token: tempToken,
      };
      const companyIdData: any = await bot.getCompanyDetails(payload);
      const companyId = companyIdData.data;
      // console.log("companyId", companyId);
      const { tenantId } = userInfo;
      const teamsCredsId = teamsBotCredentials.id;
      const newCredentials: TeamsBotCredentials = {
        teamsAppId: teamsBotCredentials.teamsAppId,
        conversationId: teamsBotCredentials.conversationId,
        channelId: teamsBotCredentials.channelId,
        channelName: teamsBotCredentials.channelName,
        companyId,
        accessToken: teamsBotCredentials.accessToken,
        tokenExpiry: teamsBotCredentials.tokenExpiry,
        tenantId, // getting this from teams context
        teamId: teamsBotCredentials.teamId,
        teamworkTagId: null,
      };
      // console.log("newCredentials", newCredentials);
      await bot.updateTeamsBotCredential(teamsCredsId, newCredentials);
      // update state
      setIsAccountLinked(true);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      setErrorMessage("Invalid OTP code, please try again.");
      return;
    }
  };

  return (
    <form onSubmit={handleOtpSubmit}>
      <p className="instruction">
        Please enter the OTP code that has been sent to your email address.
      </p>
      <div style={{ marginBottom: 14 }}>
        <Input
          id={fieldIdOtp}
          onChange={onOtpChange}
          placeholder="OTP Code"
          className="mobile-field"
        />
      </div>

      {errorMessage && (
        <MessageBar
          key="error"
          intent="error"
          style={{ marginBottom: 24, maxWidth: 340 }}
        >
          <MessageBarBody>{errorMessage}</MessageBarBody>
        </MessageBar>
      )}
      <div style={{ display: "flex" }}>
        {errorMessage && (
          <Button
            onClick={() => {
              setHasCode(false);
              setShowLoginForm(true);
            }}
            className="btn-mint-outline btn-auto"
            style={{ marginRight: 16 }}
          >
            Go back
          </Button>
        )}
        <Button type="submit" className="btn-mint" disabled={isLoading}>
          Submit
        </Button>
        {isLoading && (
          <Spinner appearance="primary" style={{ marginLeft: 12 }} />
        )}
      </div>
    </form>
  );
};

export default OtpForm;
