import { useData } from "@microsoft/teamsfx-react";
import { Image, ProgressBar } from "@fluentui/react-components";
import { UserInfo } from "@microsoft/teamsfx";

import * as bot from "../api/bot.service";
import { SubscriptionDetails } from "../types/api-types";
import CreditsBalance from "./CreditsBalance";
import Form from "./form/";
import NoSubscription from "./NoSubscription";

import "./Tab.css";

export default function Tab({ userInfo }: { userInfo: UserInfo }) {
  const { loading, data, error } = useData(async () => {
    let teamsSubscription: SubscriptionDetails = {
      hasSubscription: false,
      pointsBalance: 0,
      pointValue: 0,
      adminName: "",
    };
    if (userInfo) {
      console.log("userInfo", userInfo);
      const { tenantId, objectId } = userInfo;
      try {
        const subscriptionResponse = await bot.getSubscriptionStatus(
          objectId,
          tenantId
        );
        console.log("subscriptionResponse", subscriptionResponse);
        const details: any = subscriptionResponse.data.data;
        // P2P subscription saved in key 3.
        teamsSubscription = {
          hasSubscription: details[3].isActive,
          ...details[3].details,
        };
      } catch (e) {
        teamsSubscription.hasSubscription = false;
      }

      return { userInfo, teamsSubscription };
    }
  });

  const teamsSubscription = loading || error ? null : data!.teamsSubscription;

  const TabContent = () => (
    <div className="ms-Grid">
      <div className="ms-Grid-row container">
        <div
          className="ms-Grid-col ms-xl6 ms-sm12"
          style={{ paddingRight: 48 }}
        >
          <Image src="logo-white.svg" className="logo" />
          <h3>
            Welcome to <strong>Zuzo</strong>!
          </h3>
          <p>Zuzo helps teams build a culture of appreciation with:</p>
          <ul>
            <li>Recognition in Microsoft Team as written praise </li>
            <li>Rewards on your Zuzo card as cash </li>
          </ul>{" "}
          <p>
            Use the form on the right at any point to choose who you're praising
            and how much to give them.
          </p>
          <p>
            You can also add a reward to praise given by someone else by
            clicking the "+ Reward" button under a nomination.
          </p>
          <CreditsBalance />
        </div>

        <div className="ms-Grid-col ms-xl6 ms-sm12 grid-border-left">
          <Form {...data!} />
        </div>
      </div>
    </div>
  );

  return loading ? (
    <ProgressBar color="brand" thickness="medium" />
  ) : teamsSubscription?.hasSubscription ? (
    <TabContent />
  ) : (
    <NoSubscription />
  );
}
