import { UserInfo } from "@microsoft/teamsfx";
import { Image } from "@fluentui/react-components";
import { useState } from "react";
import { TeamsBotCredentialUpdateResponse } from "../../types/api-types";

// screen specific components
import WelcomeMessage from "./components/WelcomeMessage";
import LoginForm from "./components/LoginForm";
import OtpForm from "./components/OtpForm";
import AccountLinkedInstructions from "./components/AccountLinked";

// screen styles
import "./index.css";

const Login = ({
  userInfo,
  teamsBotCredentials,
}: {
  userInfo: UserInfo;
  teamsBotCredentials: TeamsBotCredentialUpdateResponse;
}) => {
  const [isAccountLinked, setIsAccountLinked] = useState<boolean>(false);
  const [hasCode, setHasCode] = useState<boolean>(false);
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false);

  return (
    <div id="login-page">
      <div className="ms-Grid">
        <div className="ms-Grid-row container">
          <div className="ms-Grid-col ms-sm12 column-logo">
            <Image src="logo-white.svg" className="logo" />
          </div>
          <div className="ms-Grid-col ms-sm12 ms-lg8 column-first">
            <h1>
              The <span className="mint">Ultimate</span> Way To Reward Your Team
            </h1>
            {!showLoginForm && (
              <WelcomeMessage setShowLoginForm={setShowLoginForm} />
            )}
            {!hasCode && showLoginForm && <LoginForm setHasCode={setHasCode} />}
            {hasCode && !isAccountLinked && (
              <OtpForm
                setIsAccountLinked={setIsAccountLinked}
                setShowLoginForm={setShowLoginForm}
                setHasCode={setHasCode}
                teamsBotCredentials={teamsBotCredentials}
                userInfo={userInfo}
              />
            )}
            {isAccountLinked && <AccountLinkedInstructions />}
          </div>
          <div className="ms-Grid-col ms-sm12 ms-lg4 column-last">
            <Image src="phone-bottom-left.svg" className="phone-bottom-left" />
            <Image src="phone-with-zuzo.svg" className="phone-with-zuzo" />
            <Image src="phone-top-right.svg" className="phone-top-right" />
          </div>
          <div className="ms-Grid-col ms-sm12 no-account">
            <p style={{ fontSize: 12 }}>
              Don't have an account yet? <br />
              Sign up and get started by contacting{" "}
              <a
                href="mailto:geoff@zuzocard.com?subject=Zuzo Teams App Enquiry"
                className="mint"
              >
                geoff@zuzocard.com
              </a>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
